

<div class="kex-home-search-section">
  <h2>Suche</h2>
  <kex-search-field (searchStart)="goToSearchPage()"></kex-search-field>
</div>

<div class="kex-home-widgets">
  <kex-widget-skills class="kex-widget"/>
  <kex-widget-experience class="kex-widget"/>
  <kex-widget-contact-data class="kex-widget"/>
  <kex-widget-feedback class="kex-widget"/>
  <kex-widget-favorite-user class="kex-widget"/>
</div>




