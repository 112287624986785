<kex-search-field></kex-search-field>

<ng-container [ngSwitch]="searchResultsLoadState$ | async">
  <ng-container *ngSwitchCase="KexLoadState.LOADING">
    <mat-spinner></mat-spinner>
  </ng-container>
  <ng-container *ngSwitchCase="KexLoadState.FAILURE">
    ERROR
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ng-container *ngIf="searchResults$ | async as searchResult">
      <p class="fw-bold">Gefundene Person ({{ searchResult.count }})</p>
      <div>
        <ng-container *ngFor="let user of searchResult.userList">
          <kex-search-user-list-user [user]="user"></kex-search-user-list-user>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>



