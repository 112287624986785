<kex-toolbar></kex-toolbar>
<div class="kex-page-content">
  <ng-container [ngSwitch]="loadState">
    <ng-container *ngSwitchCase="KexLoadState.FAILURE"></ng-container>
    <ng-container *ngSwitchCase="KexLoadState.SUCCESS">
      <router-outlet></router-outlet>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <mat-spinner></mat-spinner>
    </ng-container>
  </ng-container>
</div>



