import { Component } from '@angular/core';

@Component({
  selector: 'kex-search',
  templateUrl: './kex-search.component.html',
  styleUrl: './kex-search.component.scss'
})
export class KexSearchComponent {

}
