<mat-form-field appearance="outline">
  <mat-label>Suche</mat-label>
  <input matInput type="text" [(ngModel)]="searchValue" placeholder="Fähigkeit eingeben" (keyup.enter)="onSearch()">
  <button matSuffix mat-icon-button aria-label="search" (click)="onSearch()">
    <mat-icon>search</mat-icon>
  </button>
</mat-form-field>
<div class="kex-search-options">
  <!--  <mat-form-field appearance="outline">
     <mat-label>Suchfelder</mat-label>
     <mat-select multiple>
       <mat-option value="skill">Fähigkeiten</mat-option>
       <mat-option value="experience">Erfahrungen</mat-option>
       <mat-option value="user">Benutzer</mat-option>
     </mat-select>
   </mat-form-field>
   <mat-form-field appearance="outline">
     <mat-select>
       <mat-option value="all">Alle Ergebnisse</mat-option>
       <mat-option value="exact">Wortwörtlich</mat-option>
     </mat-select>
   </mat-form-field>-->
 </div>
